// eslint-disable-next-line

import { useContext, useEffect, useState } from "react";
import { PublicContext } from "../../../context/PublicContext";
import { UserContext } from "../../../context/UserContext";
import { useNavigate, useParams } from "react-router-dom";
import { Book, User } from "../../../types";
import axios from "axios";
import myAxios from "../../../myaxios";
import styled from "styled-components";
import { isTestUser, parseErrorResponse } from "../../../utils/utils";
import { Mixpanel } from "../../../mixpanel";
import { NotificationManager } from "react-notifications";
import { trackEvent } from "../../../event-utils/track-event";
import { getUsersEmail } from "../../../event-utils/dropchat-event-track";
import {
  ChatBotEvents,
  EventCategory,
  Source,
  SubCategory,
  UserEvents,
} from "../../../event-utils/dropchat-event-enums";
import { ChatSource } from "../../../enums";
import { Chat } from "../../book-chat/chat";
import * as BookChatStyles from "./../../publicComponent/styled-public-trial";
import MainHeader from "../../header";
import ProgressIndicator from "../../../images/loading_bar.gif";
import { Modal, ProgressBar } from "react-bootstrap";
import { DEVICE_MOBILE } from "../../../constants/breakpoints";
import CustomMarkdown from "../../../utils/custom-markdown";
import { Formik } from "formik";
import { PUBLIC_USER_CHATBOT } from "../../../utils/class-names";
import MagicLinkLoginPopup from "../../magic-link-login-popup";
import MainFooter from "../../footer";
import DropChatIcon from "../../../images/drop-chat-icon.svg";
import SearchArrow from "../../../images/chat-arrow.svg";
import ChatBotIcon from "../../../images/chat-bot-icon.svg";
import UserImage from "../../../images/chat-user.svg";
import TypingLoading from "../../../images/typingLoading.gif";
import ChatArea from "../../book-chat/chat-area";
import SubscriptionLimitPopup from "../../subscription-limit-popup";
import { BookContext } from "../../../context/BookContext";

const Button = styled.button`
  border-radius: 4px;
  border: none;
  background: #1585b5;
  padding: 12px;
  color: #fff;
  margin-bottom: 3%;

  &:hover {
    background: #0b5f82;
  }
  &:focus {
    background: #0b5f82;
  }
`;

const AppDescriptionDiv = styled.div`
  display: block;
  padding-top: 2%;
  padding-bottom: 2%;
  background-color: #e2edef;
`;

const UpgradePlanButton = styled.button`
  border-radius: 4px;
  border: none;
  background: #eda600;
  padding: 12px;
  color: #fff;
  margin-bottom: 3%;

  &:hover {
    background: #0b5f82;
  }
  &:focus {
    background: #0b5f82;
  }
`;

const ChatbotBuilderChatPage = () => {
  const { deviceWidth } = useContext(PublicContext);
  const { user, totalBooksCount, userProfileSettings } =
    useContext(UserContext);
  const { books } = useContext(BookContext);
  const [isBeforeLoginTrial, setIsBeforeLoginTrial] = useState(false);
  const [currentSessionHistory, setCurrentSessionHistory] = useState<any[]>([]);
  const [publicUserQuestionAskedCount, setPublicUserQuestionAskedCount] =
    useState<number>(0);
  const [trialQuestionLimit, setTrialQuestionLimit] = useState<number>(5);
  const [isTrialQuestionLoaded, setTrialQuestionLoaded] =
    useState<boolean>(false);
  const { indexingProgress, setIndexingProgress } = useContext(PublicContext);

  const [allSessionHistory, setAllSessionHistory] = useState([]);

  const [loading, setLoading] = useState<boolean>(true);

  const [showUpdatePopup, setShowUpdatePopup] = useState(false);

  const [formRef, setFormRef] = useState<any>();
  const [questionValue, setQuestionValue] = useState("");
  // const {user} = useContext(UserContext)

  const { bookId } = useParams();
  const [bookResponse, setBookResponse] = useState<any>();

  const navigate = useNavigate();
  const [isExpiredBook, setIsExpiredBook] = useState<boolean>();

  // const validatePropmt = (prompt: string) => {
  //   let error;
  //   if (!prompt) {
  //     error = "This is Required!";
  //   }
  //   return error;
  // };
  const [thisUser, setThisUser] = useState<User>();
  const [localBook, setLocalBook] = useState<Book>({});
  const [showOverlay, setShowOverlay] = useState(false);
  // console.log("localBook------------", localBook);
  const [fileProcessingMessage, setFileProcessingMessage] =
    useState<string>("");

  // console.log("thisUser: ", thisUser);
  // console.log("response in book state-------", bookResponse);

  useEffect(() => {
    if (bookId) {
      myAxios
        .get(process.env.REACT_APP_SERVER_URL + "/books/find-expired/" + bookId)
        .then((response) => {
          // console.log("FOUND RESPONSE FROM EXPIRED BOOK: ",response.data.data.chatbotExpired)
          setIsExpiredBook(response.data.data.chatbotExpired)
        })
        .catch((error) => {
          // handle error
        });
    }
  }, [bookId])

  useEffect(() => {
    if (thisUser) {
      myAxios
        .get(
          process.env.REACT_APP_SERVER_URL +
          "/get-history-of-chats/" +
          thisUser?.id +
          "/" +
          bookId
        )
        .then((response) => {
          if (response.data) {
            setAllSessionHistory(response.data.data.allSessionHistory);
            setCurrentSessionHistory(response.data.data.currentSessionHistory);
          }
        })
        .catch((error) => {
          // console.error(
          //   "Getting error at the time of get chat history ",
          //   error
          // );
        });
    }
  }, [setAllSessionHistory, bookId, thisUser]);

  useEffect(() => {
    if (bookId) {
      axios
        .get(
          process.env.REACT_APP_SERVER_URL + "/public/getFileProcessingMessage"
        )
        .then((response) => {
          if (response && response.data) {
            setFileProcessingMessage(response.data.data);
          }
        });
      axios
        .get(
          process.env.REACT_APP_SERVER_URL + "/public/get-trial-question-limit"
        )
        .then((response) => {
          if (response && response.data && response.data.data > 0) {
            setTrialQuestionLoaded(true);
            setTrialQuestionLimit(response.data.data);
          }
        });

      axios
        .get(process.env.REACT_APP_SERVER_URL + "/books/" + bookId)
        .then((response) => {
          // console.log("response-------", response);
          setBookResponse(response);

          if (response.data) {
            setLocalBook(response.data);
            setThisUser(response.data.user);
            setPublicUserQuestionAskedCount(
              response.data.user.questionAskedCount
            );
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          // console.error("Gettings Error at the time of load book ", error);
        });
    }
  }, [setLocalBook, setThisUser, bookId]);
  const getFullAnswer = async (id: any, userQuery: any) => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/questions-and-answers/${id}`)
      .then((res: any) => {
        if (res.data) {
          const data = res.data.data;

          setCurrentSessionHistory((oldQuestions: any[]) => {
            if (oldQuestions.length > 0) {
              oldQuestions[oldQuestions.length - 1]._id = data._id;
              oldQuestions[oldQuestions.length - 1].id = data.id;
              oldQuestions[oldQuestions.length - 1].answer = data.answer;

              return [...oldQuestions];
            } else return [];
          });
          // checkAndShowLoginForm();
          if (!isTestUser(thisUser?.email))
            Mixpanel.track("Public(Trial User) User Chat Response", {
              bookId: localBook._id ? localBook._id : localBook.id,
              bookTitle: localBook.title,
              bookAuthors: localBook.authors,
              question: userQuery,
              answer: data.answer,
              email: thisUser?.email,
            });
          axios
            .put(
              process.env.REACT_APP_SERVER_URL +
              "/public/update-question-ask-count/" +
              thisUser?._id
            )
            .then((response) => {
              if (response && response.data) {
                setThisUser(response.data.data);
              }
            })
            .catch((error) => {
              // console.error(
              //   "Gettings Error at the time of update question ask count ",
              //   error
              // );
            });
          setShowOverlay(false);
        } else {
          setShowOverlay(false);
          NotificationManager.error(
            res.data && res.data.message
              ? res.data.message
              : "Unable to process your request, please try later!"
          );
          if (!isTestUser(thisUser?.email))
            Mixpanel.track("Public(Trial User) User Chat Response", {
              bookId: localBook._id ? localBook._id : localBook.id,
              bookTitle: localBook.title,
              bookAuthors: localBook.authors,
              question: userQuery,
              email: thisUser?.email,
              answer: "Getting Error",
              errror:
                res.data && res.data.message
                  ? res.data.message
                  : "Unable to process your request, please try later!",
            });
        }
      })
      .catch((error) => {
        // handle error
        // console.error(
        //   "Getting Error at the time of ASK answer from chat gpt ",
        //   error
        // );
        setShowOverlay(false);
        NotificationManager.error(parseErrorResponse(error));
        if (!isTestUser(thisUser?.email))
          Mixpanel.track("Public(Trial User) User Chat Response", {
            bookId: localBook._id ? localBook._id : localBook.id,
            bookTitle: localBook.title,
            bookAuthors: localBook.authors,
            question: userQuery,
            email: thisUser?.email,
            answer: "Getting Error",
            errror: parseErrorResponse(error),
          });
      });
  };

  const chatWithDropchat = async (userQuery: string) => {
    setShowOverlay(true);
    setCurrentSessionHistory(
      currentSessionHistory
        ? [...currentSessionHistory, { question: userQuery, answer: "" }]
        : [{ question: userQuery, answer: "" }]
    );
    if (!isTestUser(thisUser?.email)) Mixpanel.identify(thisUser?.email);
    if (!isTestUser(thisUser?.email))
      Mixpanel.track("Public(Trial User) User Try to Chat", {
        bookId: localBook._id ? localBook._id : localBook.id,
        bookTitle: localBook.title,
        bookAuthors: localBook.authors,
        question: userQuery,
        email: thisUser?.email,
      });
    trackEvent({
      email: getUsersEmail(thisUser),
      eventCategory: EventCategory.CHATBOT,
      event: ChatBotEvents.USER_ASKED_QUESTION_AT_PUBLIC_CHATBOT,
      subCategory: SubCategory.EVENT,
      source: Source.PUBLIC,
      bookId: bookId,
      rawData: {
        question: userQuery,
      },
    });

    const requestBody = {
      question: userQuery,
      bookId: bookId,
      source: ChatSource.public,
    };

    const chat = new Chat(
      requestBody,
      (token: string) => {
        setCurrentSessionHistory((oldQuestions: any[]) => {
          if (oldQuestions.length > 0) {
            oldQuestions[oldQuestions.length - 1].answer = oldQuestions[
              oldQuestions.length - 1
            ].answer
              ? oldQuestions[oldQuestions.length - 1].answer + token
              : token;
            return [...oldQuestions];
          } else return [];
        });
      },
      (data: any) => {
        getFullAnswer(data, userQuery);
      },
      (data: any) => {
        NotificationManager.error(data);
      },
      ""
      // user?.jwt
    );

    await chat.startChat();
  };

  const submitQuestion = (values: any, actions: any) => {
    actions.resetForm({
      values: {
        prompt: "",
      },
    });

    if (publicUserQuestionAskedCount >= trialQuestionLimit) {
      setShowUpdatePopup(true);
      trackEvent({
        email: getUsersEmail(thisUser),
        eventCategory: EventCategory.CHATBOT,
        event: ChatBotEvents.USER_GOES_AVOVE_QUESTION_LIMIT_ON_PUBLIC_CHATBOT,
        subCategory: SubCategory.EVENT,
        source: Source.PUBLIC,
        bookId: bookId,
      });

      return;
    }
    chatWithDropchat(values.prompt);
    setPublicUserQuestionAskedCount((oldState) => oldState + 1);
  };

  const handleRefreshClick = (event: any) => {
    navigate("/chatbot-builder");
  };

  const handleLogin = (event: any) => {
    navigate("/magic-login");
    // window.location.reload();
  };

  // console.log(
  //   "user.currentSubscription.meta.noOfBooks",
  //   user?.currentSubscription?.meta.noOfBooks,
  //   " userProfileSettings.totalCalculatedBooks" +
  //     parseInt(userProfileSettings.totalCalculatedBooks, 10)
  // );

  // console.log("bookResponse.data.roundProgressPercentage", bookResponse.data.roundProgressPercentage)

  let totalPlanBooks: any = user?.currentSubscription?.meta.noOfBooks;
  // console.log("totalPlanBooks: ", totalPlanBooks);

  let usedBook: any = userProfileSettings.totalCalculatedBooks;
  // console.log("usedBook: ", usedBook);

  return (
    <>
      {user ? (
        <MainHeader isUserPage={true} activeFrom={"fromDemo"} />
      ) : (
        <MainHeader landing={false} isBeforeLoginTrial={isBeforeLoginTrial} />
      )}

      <AppDescriptionDiv>
        <center>
          <h4>
            <b>Welcome to Dropchat Chatbot Builder</b>
          </h4>
          <br />
          <h6>
            Build your first chatbot in minutes! Just tell us about your company
            or website, and we’ll use that info as the chatbot’s knowledge base.
          </h6>
          <h6>
            Create a smart, human-like chat experience for your audience—simple,
            fast, and easy.
          </h6>
        </center>
      </AppDescriptionDiv>
      <BookChatStyles.MainContainer fluid style={{ position: "relative" }}>
        {bookResponse?.data?.roundProgressPercentage &&
          bookResponse?.data?.roundProgressPercentage < 100 && (
            <>
              <BookChatStyles.StatusLoaderPopup
                style={{
                  background: "rgba(30 30 30 / 30%)",
                  position: "absolute",
                  padding: "20%",
                  height: "100%",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    flexDirection: "column",
                    background: "white",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <p
                    style={{
                      color: "black",
                      fontSize: "20px",
                      marginTop: "2%",
                    }}
                  >
                    {bookResponse.data.roundProgressPercentage + "%"}
                  </p>
                  {bookResponse.data.roundProgressPercentage < 1 ? (
                    <div
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        alt="imgProgress"
                        src={ProgressIndicator}
                        style={{ width: "140%" }}
                      ></img>
                    </div>
                  ) : (
                    <ProgressBar
                      animated
                      variant={
                        bookResponse.data.roundProgressPercentage < 34
                          ? "warning"
                          : bookResponse.data.roundProgressPercentage < 68
                            ? "info"
                            : "success"
                      }
                      now={bookResponse.data.roundProgressPercentage}
                      style={{ height: "14px", width: "30%" }}
                    />
                  )}
                  <div
                    style={{
                      // background: 'white',
                      // background:  "#2f4c95",
                      fontSize: "16px",
                      color: "black",
                      fontWeight: "bold",
                      textAlign: "center",
                      padding: "5%",
                    }}
                  >
                    Hello! We wanted to let you know that it will take a few
                    minutes to process this item. Don't worry, though - once the
                    file processing is finished, you'll be able to ask any
                    questions you have. You can leave this screen and return a
                    few minutes later to check status.`
                  </div>
                </div>
              </BookChatStyles.StatusLoaderPopup>
            </>
          )}
        {user ? (
          // parseInt(usedBook, 10) >= parseInt(totalPlanBooks, 10)
          isExpiredBook
           ? (
            <BookChatStyles.LeftColumnDiv>
              <p
                style={{ fontSize: "24px", marginBottom: "10%", color: "red" }}
              >
                You have reached your {totalPlanBooks} Chatbot limit.
              </p>
              <p
                style={{
                  fontSize: "16px",
                  marginBottom: "10%",
                  color: "#797979",
                }}
              >
                For more chatbots please upgrade your plan.
              </p>
              <UpgradePlanButton
                onClick={() => {
                  navigate("/subscription");
                }}
              >
                Upgrade Plan
              </UpgradePlanButton>
            </BookChatStyles.LeftColumnDiv>
          ) : (
            <BookChatStyles.LeftColumnDiv>
              <p style={{ fontSize: "24px", marginBottom: "10%" }}>
                Thankyou! Your chatbot has created successfully.
              </p>
              <p
                style={{
                  fontSize: "16px",
                  marginBottom: "10%",
                  color: "#797979",
                }}
              >
                We have added this chatbot in “My Chatbot” page for future
                access.
              </p>
              <Button onClick={handleRefreshClick}>
                Create Additional Chatbots
              </Button>
            </BookChatStyles.LeftColumnDiv>
          )
        ) : (
          <BookChatStyles.LeftColumnDiv>
            <p style={{ fontSize: "24px", marginBottom: "10%" }}>
              Thank you! Your demo chatbot is ready. Type in questions to start
              testing it out!
            </p>
            <p
              style={{
                fontSize: "16px",
                marginBottom: "10%",
                color: "#797979",
              }}
            >
              Welcome to your demo conversation! Feel free to ask questions
              based on the information you provided. If the chatbot doesn’t have
              an answer, it’s likely because that information wasn’t included.
            </p>
            <Button onClick={handleRefreshClick}>Try Demo Again</Button>
            <Button onClick={handleLogin}>Login To Customize</Button>
          </BookChatStyles.LeftColumnDiv>
        )}

        <BookChatStyles.RightColumn xs={deviceWidth === DEVICE_MOBILE ? 3 : 9}>
          <BookChatStyles.ChatBarHeader
            style={{ justifyContent: "start", color: "#fff" }}
          >
            <img style={{ height: "30px" }} src={DropChatIcon} alt="" />{" "}
            &nbsp;&nbsp;{localBook.title ? localBook.title : "Header"}
          </BookChatStyles.ChatBarHeader>

          <BookChatStyles.ChatInputBox sm={12}>
            <BookChatStyles.RightContentRow style={{ marginLeft: "0" }}>
              {user && 
              // parseInt(usedBook, 10) < parseInt(totalPlanBooks, 10)
              !isExpiredBook
               ? (
                <ChatArea fromChatbotBuilder={true}/>
              ) : (
                <>
                  {" "}
                  <BookChatStyles.ChatBotContent sm={12}>
                    <BookChatStyles.ScrollDivForTrial>
                      <>
                        <BookChatStyles.QuestionAnswerSection
                          style={{ marginTop: "0" }}
                        >
                          {currentSessionHistory &&
                            currentSessionHistory.length > 0 &&
                            currentSessionHistory.map((data: any) => (
                              <BookChatStyles.SignleQuestionAnswerSection>
                                <BookChatStyles.QuestionSection>
                                  <img
                                    width={
                                      deviceWidth === DEVICE_MOBILE
                                        ? "15"
                                        : "30"
                                    }
                                    src={UserImage}
                                    alt="user_image"
                                  />
                                  &nbsp;&nbsp;
                                  <h5
                                    style={{
                                      fontSize:
                                        deviceWidth === DEVICE_MOBILE
                                          ? "0.75rem"
                                          : "1rem",
                                    }}
                                  >
                                    {data.question}
                                  </h5>
                                </BookChatStyles.QuestionSection>
                                <BookChatStyles.ResponseSection>
                                  <div>
                                    <img
                                      width={
                                        deviceWidth === DEVICE_MOBILE
                                          ? "15"
                                          : "30"
                                      }
                                      src={ChatBotIcon}
                                      alt="user_image"
                                      style={{
                                        display: "flex",
                                        alignSelf: "baseline",
                                      }}
                                    />
                                    &nbsp;&nbsp;
                                    {data.answer && data.answer.length > 0 ? (
                                      <BookChatStyles.MarkDownContent>
                                        <CustomMarkdown text={data.answer} />
                                      </BookChatStyles.MarkDownContent>
                                    ) : (
                                      <h5 style={{ margin: 0 }}>
                                        {`${data.answer}`}
                                        {data._id ? null : (
                                          <img
                                            width={
                                              deviceWidth === DEVICE_MOBILE
                                                ? "15"
                                                : "30"
                                            }
                                            src={TypingLoading}
                                            alt="..."
                                          />
                                        )}
                                      </h5>
                                    )}
                                  </div>
                                </BookChatStyles.ResponseSection>
                              </BookChatStyles.SignleQuestionAnswerSection>
                            ))}
                        </BookChatStyles.QuestionAnswerSection>
                      </>

                      {/*  */}
                    </BookChatStyles.ScrollDivForTrial>
                  </BookChatStyles.ChatBotContent>
                  <>
                    <BookChatStyles.FieldColumn sm={12}>
                      <Formik
                        initialValues={{
                          prompt: questionValue,
                        }}
                        onSubmit={(values, actions) => {
                          setQuestionValue("");
                          submitQuestion(values, actions);
                        }}
                        enableReinitialize
                      >
                        {({ errors, touched, isValidating }: any) => (
                          <BookChatStyles.BookFetchForm
                            ref={(el) => setFormRef(el)}
                            style={{ padding: "0", paddingBottom: "6px" }}
                            className={PUBLIC_USER_CHATBOT}
                          >
                            <BookChatStyles.FieldSection>
                              <BookChatStyles.FormInput
                                name="prompt"
                                as="textarea"
                                value={questionValue}
                                placeholder={
                                  'Ask me anything from "' +
                                  localBook.title +
                                  '"'
                                }
                                onChange={(e) =>
                                  setQuestionValue(e.target.value)
                                }
                                onKeyDown={(e) => {
                                  // eslint-disable-next-line
                                  if (e.keyCode == 13 && e.shiftKey == false) {
                                    e.preventDefault();
                                    formRef.requestSubmit();
                                  }
                                }}
                                style={{
                                  paddingRight: "2.6rem",
                                  resize: "none",
                                  height: "46px",
                                  padding: "11px",
                                }}
                              />
                              <BookChatStyles.FetchBookButton
                                type="submit"
                                style={{ top: "10px" }}
                              >
                                <img
                                  width={25}
                                  src={SearchArrow}
                                  alt="search"
                                />
                              </BookChatStyles.FetchBookButton>
                            </BookChatStyles.FieldSection>
                            {isTrialQuestionLoaded && (
                              <BookChatStyles.RestrictedSection
                                style={{
                                  margin: "0.5rem 0 0 0",
                                  justifyContent: "start",
                                  position: "relative",
                                }}
                              >
                                <BookChatStyles.RemainingCount
                                  xs={deviceWidth === DEVICE_MOBILE ? 8 : 3}
                                >
                                  {/* {user &&
                                user.currentSubscription?.name ===
                                  "Free Plan Monthly" ? (
                                  <> */}
                                  {isTrialQuestionLoaded && (
                                    <>
                                      {publicUserQuestionAskedCount
                                        ? trialQuestionLimit -
                                          publicUserQuestionAskedCount <
                                          0
                                          ? 0
                                          : trialQuestionLimit -
                                          publicUserQuestionAskedCount
                                        : trialQuestionLimit}{" "}
                                      / {trialQuestionLimit} Demo questions
                                      remaining
                                    </>
                                  )}
                                  {/* </>
                                ) : (
                                  <></>
                                )} */}
                                </BookChatStyles.RemainingCount>
                                {!user && (
                                  <BookChatStyles.LoginLink
                                    style={{
                                      justifyContent: "start",
                                    }}
                                    xs={deviceWidth === DEVICE_MOBILE ? 4 : 2}
                                    onClick={() => {
                                      trackEvent({
                                        email: getUsersEmail(thisUser),
                                        eventCategory: EventCategory.USER,
                                        event:
                                          UserEvents.USER_TRY_TO_LOGIN_FROM_PUBLIC_CHATBOT,
                                        subCategory: SubCategory.EVENT,
                                        source: Source.PUBLIC,
                                        bookId: bookId,
                                      });
                                      navigate("/magic-login");
                                    }}
                                  >
                                    Login to Dropchat
                                  </BookChatStyles.LoginLink>
                                )}
                                <p
                                  style={{
                                    width: "20%",
                                    position: "absolute",
                                    marginTop: "1.2%",
                                    marginLeft: "80%",
                                    fontSize: "14px",
                                    color: "darkgrey",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Powered By Dropchat
                                </p>
                              </BookChatStyles.RestrictedSection>
                            )}
                          </BookChatStyles.BookFetchForm>
                        )}
                      </Formik>
                    </BookChatStyles.FieldColumn>
                  </>
                </>
              )}
              {/*  */}
            </BookChatStyles.RightContentRow>
          </BookChatStyles.ChatInputBox>
        </BookChatStyles.RightColumn>

        {showUpdatePopup && (
          <>
            <Modal show={true} backdrop="static" keyboard={false}>
              <SubscriptionLimitPopup
                restrictionMessage={`Your plan limit has been exceeded!`}
                messageregardingrest={`For more question please upgrade your plan.`}
              // highliter={`isBookHighliter`}
              ></SubscriptionLimitPopup>
            </Modal>
          </>
        )}
      </BookChatStyles.MainContainer>
      {!user && <MainFooter />}
    </>
  );
};

export default ChatbotBuilderChatPage;
